import React from "react";
import { Link } from "gatsby"
import Layout from "../components/layout";
import SEO from "../components/seo";
import racers from "../images/backgrounds/Retul-road-racers.jpg";

function Takk() {
  return (
    <Layout>
      <SEO title="Takk fyrir" />
				<div className="w-screen h-screen relative">

					<img src={racers} alt="Hjólarar" className="object-cover object-center mx-auto" style={{
						maxWidth: 'none'
					}} />

					<div
						className="container p-8 max-w-6xl mx-auto sm:absolute sm:inset-0 flex justify-left items-center"
						>

					<div className="w-full sm:w-2/3 md:w-1/2">
						<h2 className="text-3xl md:text-5xl xl:text-6xl">Takk fyrir</h2>

						<p className="text-2xl">Takk fyrir að hafa samband. Við svörum fljótlega.</p>

						<p className="text-lg uppercase hover:text-retulBlue"><Link to="/">Aftur á forsíðu</Link>
						</p>
					</div>
				</div>
			</div>
    </Layout>
  );
}

export default Takk;
